<template>
  <div>
    <click-to-edit
      v-if="scope.row.scale"
      v-model="scope.row.scale.unit"
      type="select"
      :select-options="options"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.unit')"
      placeholder=""
      @input="saveElementPropChange(scope.row, 'scale', {unit:$event})"
    >
    </click-to-edit>
    <div v-else style="color: #b1b1b2;">Сначала необходимо выбрать шкалу</div>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/cherkizovo/variables";

export default {
  name: 'element-scale-plan-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  mounted() {
    this.options = variables.units.map(name => ({id: name, name: name}));
  },
  data() {
    return {
      options: [],
    }
  },

  methods: {}
}

</script>

<style>

</style>
